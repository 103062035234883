/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Card,
    Container,
    Row,
    Col,
} from "reactstrap";

export default function Gallery() {
    return (
        <>
            <div className="cd-section" id="blogs">
                <div className="section " id="blogs-4">
                    <Container >
                        <Row>
                            <Col lg="3">
                                <Card

                                    className="card-blog card-background"
                                    data-animation="zooming"
                                >
                                    <div
                                        className="full-background"
                                        style={{
                                            backgroundImage:
                                                "url(" +
                                                require("assets/img/viktor-forgacs.jpg").default +
                                                ")",
                                        }}
                                    />
                                </Card>
                            </Col>
                            <Col lg="3">
                                <Card
                                    className="card-blog card-background"
                                    data-animation="zooming"
                                >
                                    <div
                                        className="full-background"
                                        style={{
                                            backgroundImage:
                                                "url(" +
                                                require("assets/img/rezaul-karim.jpg").default +
                                                ")",
                                        }}
                                    />

                                </Card>
                            </Col>
                            <Col lg="3">
                                <Card
                                    className="card-blog card-background"
                                    data-animation="zooming"
                                >
                                    <div
                                        className="full-background"
                                        style={{
                                            backgroundImage:
                                                "url(" +
                                                require("assets/img/pawel-nolbert.jpg").default +
                                                ")",
                                        }}
                                    />

                                </Card>
                            </Col>
                            <Col lg="3">
                                <Card
                                    className="card-blog card-background"
                                    data-animation="zooming"
                                >
                                    <div
                                        className="full-background"
                                        style={{
                                            backgroundImage:
                                                "url(" +
                                                require("assets/img/mark-finn.jpg").default +
                                                ")",
                                        }}
                                    />

                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3">
                                <Card
                                    className="card-blog card-background"
                                    data-animation="zooming"
                                >
                                    <div
                                        className="full-background"
                                        style={{
                                            backgroundImage:
                                                "url(" +
                                                require("assets/img/viktor-forgacs.jpg").default +
                                                ")",
                                        }}
                                    />
                                </Card>
                            </Col>
                            <Col md="3">
                                <Card
                                    className="card-blog card-background"
                                    data-animation="zooming"
                                >
                                    <div
                                        className="full-background"
                                        style={{
                                            backgroundImage:
                                                "url(" +
                                                require("assets/img/rezaul-karim.jpg").default +
                                                ")",
                                        }}
                                    />

                                </Card>
                            </Col>
                            <Col md="3">
                                <Card
                                    className="card-blog card-background"
                                    data-animation="zooming"
                                >
                                    <div
                                        className="full-background"
                                        style={{
                                            backgroundImage:
                                                "url(" +
                                                require("assets/img/pawel-nolbert.jpg").default +
                                                ")",
                                        }}
                                    />

                                </Card>
                            </Col>
                            <Col md="3">
                                <Card
                                    className="card-blog card-background"
                                    data-animation="zooming"
                                >
                                    <div
                                        className="full-background"
                                        style={{
                                            backgroundImage:
                                                "url(" +
                                                require("assets/img/mark-finn.jpg").default +
                                                ")",
                                        }}
                                    />

                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>{" "}
        </>
    );
}
