/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";


export default function Intro() {
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("blog-post");
    return function cleanup() {
      document.body.classList.remove("blog-post");
    };
  }, []);
  return (
    <>
      <div className="wrapper" ref={wrapper}>
        <div className=" section-blog-info">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                <Row>
                  <Col className="ml-auto mr-auto" md="12">
                    <Card className="card-profile profile-bg">
                      <CardHeader
                        style={{
                          backgroundImage:
                            "url(" +
                            require("assets/img/ruvim-noga.jpg").default +
                            ")",
                        }}
                      >
                        <div className="card-avatar">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              alt="..."
                              className="img img-raised"
                              src={require("assets/img/lora.jpg").default}
                            />
                          </a>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <CardTitle tag="h3" className="text-primary">Lucky Likey</CardTitle>

                        <p className="card-description" md="6">
                          <p> An 11-year-old boy named Likey is trying to find himself. He's always been like this. He doesn't know what he wants to be when he grows up, he can't decide his favorite TV show, or his favorite food. Everyone says that he has many personalities, but he only seems to know how to be one person at a time. But during the day it's like waking up in a new world every day with someone else making decisions for him.</p>
                          <p>One day Likey opens his phone and opens Facebook where his mom has set up all sorts of different accounts just for him. The next morning, when he is about to open his eyes, the first thing that pops into his head is that today is the day he will be someone else.</p>
                          <p>Soon after "waking up" one morning, Likey realizes that in the process of being someone else all day, he has also become someone new in himself. It's an odd feeling</p>
                        </p>
                      </CardBody>
                      <CardFooter>
                        <div>
                          <Button className="btn-simple" color="primary" href="https://opensea.io" target="_blank">
                            AVAILABLE ON OPENSEA
                          </Button>
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
